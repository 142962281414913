#root {
  --swiper-theme-color: var(--primary-main);
}

.heder-loader_box__YDwdv{width:100%;height:100px}@media(min-width: 600px){.heder-loader_box__YDwdv{height:128px}}
@font-face {
font-family: '__gotham_c78845';
src: url(/_next/static/media/f76e356ec8704cae-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: '__gotham_c78845';
src: url(/_next/static/media/9cc3af22c502e1d2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}@font-face {font-family: '__gotham_Fallback_c78845';src: local("Arial");ascent-override: 84.58%;descent-override: 21.14%;line-gap-override: 0.00%;size-adjust: 113.51%
}.__className_c78845 {font-family: '__gotham_c78845', '__gotham_Fallback_c78845'
}

@font-face {
font-family: '__carlaSans_f05349';
src: url(/_next/static/media/1d470719f2ba7a47-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__carlaSans_Fallback_f05349';src: local("Arial");ascent-override: 65.08%;descent-override: 23.00%;line-gap-override: 0.00%;size-adjust: 143.05%
}.__className_f05349 {font-family: '__carlaSans_f05349', '__carlaSans_Fallback_f05349';font-weight: 400
}

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote::before,blockquote::after,q::before,q::after{content:"";content:none}table{border-collapse:collapse;border-spacing:0}button,input,textarea{border:none;outline:none}*{box-sizing:border-box}
html,body{font-size:15px;min-height:100vh}body{font-family:var(--font-primary);line-height:1.6;color:var(--text-primary)}a{text-decoration:none}button,input,select,textarea{background:rgba(0,0,0,0);border:none;outline:none;padding:0;margin:0;font:inherit}textarea{resize:none}img{display:block}button{cursor:pointer;color:var(--text-primary);-webkit-tap-highlight-color:rgba(0,0,0,0)}#root{display:flex;flex-direction:column;min-height:100vh}#root>main{flex:100 1}h1,h2,h3,h4,h5,h6{font-family:var(--font-secondary);font-weight:400;line-height:1.26}h1{font-size:48px}@media(max-width: 899px){h1{font-size:32px}}h2{font-size:36px}@media(max-width: 899px){h2{font-size:24px}}h3{font-size:32px}h4{font-size:20px}svg{font-size:inherit}.no-scroll{overflow:hidden !important}
