@import './reset.css';

html,
body {
  font-size: 15px;
  min-height: 100vh;
}

body {
  font-family: var(--font-primary);
  line-height: 1.6;
  color: var(--text-primary);
}

a {
  text-decoration: none;
}

button,
input,
select,
textarea {
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  font: inherit;
}

textarea {
  resize: none;
}

img {
  display: block;
}

button {
  cursor: pointer;
  color: var(--text-primary);
  -webkit-tap-highlight-color: transparent;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > main {
    flex: 100;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-secondary);
  font-weight: 400;
  line-height: 1.26;
}

h1 {
  font-size: 48px;

  @media (--md-down) {
    font-size: 32px;
  }
}

h2 {
  font-size: 36px;

  @media (--md-down) {
    font-size: 24px;
  }
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 20px;
}

svg {
  font-size: inherit;
}

.no-scroll {
  overflow: hidden !important;
}
